import i18next from "i18next";

const InstanceLogMixin = {
	methods: {
		getAutomaticBackupInfo() {
			const automaticBackup = this.getPropertyValue(this.action.payload, "AutomaticBackup");
			return automaticBackup.frequency === 'never'
				? i18next.t('activityLog.suffix.toStatus', {status: `<b>${i18next.t('message.status.disabled')}</b>`})
				: i18next.t('activityLog.suffix.toStatus', {status: `<b>${i18next.t('message.status.enabled')}</b>`});
		},
		getBackupInfo() {
			const backup = this.getPropertyValue(this.action.payload, "Backup");
			return `<b>${backup.location_details.filename}</b>`;
		},
		getInvitedUserInfo() {
			const user = this.getPropertyValue(this.action.payload, "User");
			return `<b>${user.email}</b>`
		},
		getDeletedSharedUsersInfo() {
			const resources = this.getPropertyValue(this.action.payload, "Resource");
			let text = "";
			resources.forEach(function (item) {
				text += `<b>${item.email}</b><br>`;
			})
			return text;
		},
		getCreatedSharedUsersInfo() {
			const users = this.getPropertyValues(this.action.payload, "User")
			let text = "";
			users.forEach(function (user) {
				const name = user.first_name || user.last_name ? user.first_name + " " + user.last_name : user.email;
				text += `<b>${name}</b><br>`
			})
			return text;
		},
		getUpdatedSharingUsersInfo() {
			const users = this.getPropertyValues(this.action.payload, "User")
			let text = "";
			users.forEach(function (user) {
				const name = user.first_name || user.last_name ? user.first_name + " " + user.last_name : user.email;

				let role = user.application_accesses[0].role.replace(/_/g, ' ');
				role = role.replace(/\b\w/g, function (l) {
					return l.toUpperCase();
				});
				text += i18next.t('activityLog.suffix.toRole', {
					name: `<b>${name}</b>`,
					role: `<b>${role}</b>`
				})
				text += `<br>`
			})
			return text;
		},
		getLabelInfo() {
			const label = this.getPropertyValue(this.action.payload, "Label");
			return `<span style="background-color: ${label.color}; color: white; padding: 1px 8px; border-radius: 15px;">${label.title}</span>`
		},
		getDomainInfo() {
			const instance = this.getPropertyValue(this.action.payload, "Application");
			return `<b>${instance.domain}</b>`;
		},
		getCreatedStagingInfo() {
			const instances = this.getPropertyValues(this.action.payload, "Application")
			return `<b>${instances[1].domain}</b>`;
		},
		getInstanceInfo() {
			const instance = this.getPropertyValue(this.action.payload, "Application");
			return `<b>${instance.name}</b>`;
		},
		getInstanceVersionInfo() {
			const instance = this.getPropertyValue(this.action.payload, "Application");
			return `<b>${instance.version}</b>`;
		},
		getAutoUpdateInfo() {
			const resource = this.getPropertyValue(this.action.payload, "Resource");
			let text = i18next.t('activityLog.suffix.wordpressStatus', {
				status: resource.auto_update ? `<b>${i18next.t('message.status.enabled')}</b>` : `<b>${i18next.t('message.status.disabled')}</b>`
			})
			text += i18next.t('activityLog.suffix.pluginStatus', {
				status: resource.auto_update_plugins ? `<b>${i18next.t('message.status.enabled')}</b>` : `<b>${i18next.t('message.status.disabled')}</b>`
			})
			text += i18next.t('activityLog.suffix.themeStatus', {
				status: resource.auto_update_themes ? `<b>${i18next.t('message.status.enabled')}</b>` : `<b>${i18next.t('message.status.disabled')}</b>`
			})
			return text;
		},
		getDeveloperSettingsInfo() {
			const resource = this.getPropertyValue(this.action.payload, "Resource");
			return resource
				? i18next.t('activityLog.suffix.toStatus', {status: `<b>${i18next.t('message.status.enabled')}</b>`})
				: i18next.t('activityLog.suffix.toStatus', {status: `<b>${i18next.t('message.status.disabled')}</b>`});
		},
		getThemeInfo() {
			const theme = this.getPropertyValue(this.action.payload, "Resource");
			return `<b>${theme.title}</b>`;
		},
		getPluginInfo() {
			const plugin = this.getPropertyValue(this.action.payload, "PluginResource");
			return `<b>${plugin.title}</b>`;
		},
		getWordpressUser() {
			const user = this.getPropertyValue(this.action.payload, "Resource");
			return `<b>${user.email}</b>`;
		},
		getWordPressConfigInfo() {
			const config = this.getPropertyValue(this.action.payload, "Resource");
			return `<b>${config}</b>`;
		},
		getCloudflareInfo() {
			const config = this.getPropertyValue(this.action.payload, "Resource");
			if (typeof config === 'boolean') {
				return config
					? i18next.t('activityLog.suffix.toStatus', {status: `<b>${i18next.t('message.status.enabled')}</b>`})
					: i18next.t('activityLog.suffix.toStatus', {status: `<b>${i18next.t('message.status.disabled')}</b>`});
			}
			i18next.t('activityLog.suffix.toStatus', {status: `<b>${this.capitalizeFirstLetter(config)}</b>`})
		},
		getPropertyValue(obj, key) {
			const keys = Object.keys(obj);

			for (let i = 0; i < keys.length; i++) {
				const baseClass = keys[i]
					.split(":")[0]
					.split("\\")
					.pop();
				if (baseClass === key) {
					return obj[keys[i]];
				}
			}
			return null;
		},
		getPropertyValues(obj, key) {
			const keys = Object.keys(obj);
			let objects = [];

			for (let i = 0; i < keys.length; i++) {
				const baseClass = keys[i]
					.split(":")[0]
					.split("\\")
					.pop();
				if (baseClass === key) {
					objects.push(obj[keys[i]]);
				}
			}
			return objects;
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
		}
	}
}

export default InstanceLogMixin;