<template>
  <tbody class="item">
    <tr v-for="item in 5" :key="item">
      <td v-for="itemCell in 4" :key="itemCell">
        <v-skeleton-loader class="text" type="text" />
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
tr {
  padding: 0px 8px;
  position: relative;
  &:after {
    content: "";
    border-bottom: 1px solid #E5E5F0;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
  }
}
tr td {
  border-bottom: transparent;
  &:nth-child(1) {
    width: 175px;

    .v-skeleton-loader {
      max-width: 175px;
    }
  }
  &:nth-child(2) {
    width: 280px;
  }
  &:nth-child(3) {
    max-width: 280px;
  }
  &:nth-child(4) {
    width: 120px;
  }
}

.v-skeleton-loader {
  &.avatar::v-deep {
    display: flex;
    justify-content: flex-end;
    .v-skeleton-loader__avatar {
      width: 42px;
      height: 42px;
      border-radius: 100%;
    }
  }
}
</style>