<template>
  <div
    class=""
    :class="
      $vuetify.breakpoint.width < 1400
        ? 'd-flex flex-wrap'
        : 'd-flex align-center'
    "
  >
    <v-chip
      label
      small
      class="action-name"
      :class="[labelColor, $vuetify.breakpoint.width < 1400 ? 'mr-2 mb-1' : '']"
    >
      {{ actionName }}
    </v-chip>
    <span
      v-if="action.payload && action.payload.length !== 0"
      class="p-4 grey--text text--darken-4"
      style="gap: 4px;"
      :class="
        $vuetify.breakpoint.width < 1400
          ? 'd-flex flex-wrap align-start'
          : 'd-flex align-center ml-2'
      "
      v-html="information"
    ></span>
  </div>
</template>

<script>
import InstanceLogMixin from "@/mixins/InstanceLogMixin";

export default {
  name: "LogItem",
  mixins: [InstanceLogMixin],
  props: {
    action: Object,
  },
  computed: {
    labelColor() {
      switch (this.action.request_method) {
        case "POST":
          return "success lighten-5 success--text";
        case "DELETE":
          return "red lighten-4 red--text";
        default:
          return "warning lighten-5 warning--text";
      }
    },
    actionName() {
      const action = 'activityLog.action.' + this.action.action;
      if (this.$t(action)) {
        return this.$t(action);
      } else {
        return this.action.action
          .replaceAll(".", " ")
          .replaceAll("_", " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }
    },
    information() {
      switch (this.action.action) {
        case "user.instance.shared_access.resend_access":
          return this.getInvitedUserInfo();
        case "user.instance.shared_access.create":
          return this.getCreatedSharedUsersInfo();
        case "user.instance.shared_access.update":
          return this.getUpdatedSharingUsersInfo();
        case "user.instance.shared_access.delete":
          return this.getDeletedSharedUsersInfo();
        case "user.instance.backup.update_automatic":
          return this.getAutomaticBackupInfo();
        case "user.instance.backup.restore":
        case "user.instance.backup.delete":
          return this.getBackupInfo();
        case "user.instance.assign_label":
        case "user.instance.unassign_label":
          return this.getLabelInfo();
        case "user.instance.domain.change_to_own_domain":
        case "user.instance.domain.change_to_subdomain":
        case "user.instance.staging.show_push_to_live":
          return this.getDomainInfo();
        case "user.instance.staging.create":
        case "user.instance.staging.retry_install":
          return this.getCreatedStagingInfo();
        case "user.instance.wordpress.update":
          return this.getInstanceVersionInfo();
        case "user.instance.auto_update.update":
          return this.getAutoUpdateInfo();
        case "user.instance.update_site_name":
          return this.getInstanceInfo();
        case "user.instance.maintenance_mode.update":
        case "user.instance.debug_mode.update":
          return this.getDeveloperSettingsInfo();
        case "user.instance.theme.activate":
        case "user.instance.theme.update":
        case "user.instance.theme.delete":
        case "user.instance.theme.install":
          return this.getThemeInfo();
        case "user.instance.plugin.activate":
        case "user.instance.plugin.deactivate":
        case "user.instance.plugin.update":
        case "user.instance.plugin.delete":
        case "user.instance.plugin.install":
          return this.getPluginInfo();
        case "user.instance.wordpress_config.create":
        case "user.instance.wordpress_config.update":
        case "user.instance.wordpress_config.delete":
          return this.getWordPressConfigInfo();
        case "user.instance.wordpress_user.create":
        case "user.instance.wordpress_user.update":
        case "user.instance.wordpress_user.delete":
        case "user.instance.wordpress_user.reset_password":
          return this.getWordpressUser();
        case "user.instance.cloudflare.security.update":
        case "user.instance.cloudflare.browser_check.update":
        case "user.instance.cloudflare.development_mode.update":
        case "user.instance.edge_cache.update":
          return this.getCloudflareInfo();
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss">
b {
  color: var(--v-gray-darken4) !important;
}

.action-name {
  border-radius: 5px;
  padding: 4px;
  font-size: $font-size-xxs;
  line-height: $line-height-xxs;
  font-weight: $font-weight-bold;
  margin-left: 0;
}
</style>
