<template>
  <div class="d-flex align-center">
    <user-avatar class="mr-2" :email="user.email" :size="28" />
    <span class="grey--text text--darken-4 font-weight-bold">
      {{ getName() }}
    </span>
    <v-chip
      v-if="user.role"
      class="ml-1 font-weight-bold grey lighten-4 grey--text text--darken-1"
      style="border-radius: 100px"
      small
    >
      {{ transformText(user.role) }}
    </v-chip>
    <v-chip
      v-else
      class="ml-2 font-weight-bold grey lighten-4 grey--text text--darken-1"
      style="border-radius: 100px"
      small
    >
      {{ $t('general.owner') }}
    </v-chip>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: "UserItem",
  components: { UserAvatar },
  props: {
    user: Object,
  },
  methods: {
    getName() {
      if (this.user.first_name || this.user.last_name) {
        return this.user.first_name + " " + this.user.last_name;
      }
      return this.user.email;
    },
    transformText(text) {
      let transformedText = text.replace(/_/g, " ");

      transformedText = transformedText.replace(/\b\w/g, function (l) {
        return l.toUpperCase();
      });

      return transformedText;
    },
  },
};
</script>
