<template>
  <div class="mobile-item">
    <div class="pb-3">
      <v-skeleton-loader type="text" width="25%" />
      <v-skeleton-loader type="text" width="30%" />
    </div>
    <div class="pb-3">
      <v-skeleton-loader type="text" width="25%" />
      <v-skeleton-loader type="text" width="40%" />
    </div>
    <div class="pb-3">
      <v-skeleton-loader type="text" width="25%" />
      <v-skeleton-loader type="text@2" width="80%" />
    </div>
    <div class="pb-3">
      <v-skeleton-loader type="text" width="25%" />
      <v-skeleton-loader type="text" width="15%" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.mobile-item {
  border-bottom: 1px solid map-get($gray, lighten2);
  padding: 24px 24px 16px 24px;
  .v-skeleton-loader::v-deep {
    &.actions {
      display: flex;
      flex-direction: row;
      .v-skeleton-loader__avatar {
        height: 24px;
        width: 24px;
        margin-left: 16px;
        &:first-child {
          margin-left: 0px !important;
        }
      }
    }
  }
}
</style>