<template>
  <v-card
    elevation="4"
    class="data-table"
    :class="canBeSelected ? 'data-table--selectable' : ''"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      mobile-breakpoint="200"
      class="elevation-0 custom-data-table"
      :server-items-length="itemsTotal"
    >
      <template v-slot:item.user="item">
        <user-item :user="item.item.user" />
      </template>
      <template v-slot:item.action="item">
        <log-item :action="item.item" />
      </template>
      <template v-slot:item.date="item">
        <v-tooltip open-delay="150" close-delay="150" offset-y bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="mb-0 text-right gray--text text--darken-1"
            >
              {{ moment(item.item.date).fromNow() }}
            </span>
          </template>
          <span>{{ moment(item.item.date).format("YYYY-MM-DD HH:mm") }}</span>
        </v-tooltip>
      </template>

      <template v-if="itemsLoading" v-slot:body>
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <logs-table-item-skeleton-mobile v-for="item in 5" :key="item" />
        </template>
        <template v-else>
          <logs-table-sort-skeleton />
          <logs-table-skeleton-item />
        </template>
      </template>

      <template v-slot:no-data>
        <data-iterator-no-results-container
          v-if="searchTerm"
          @clearAllFilters="clearFilters()"
          :searchTerm="searchTerm"
        />
      </template>
      <template
        v-if="$vuetify.breakpoint.width >= 1400"
        v-slot:header="{ isMobile, props, on }"
      >
        <table-custom-sort
          v-if="!itemsLoading"
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          v-on="$listeners"
        />
      </template>

      <!-- <template v-slot:item.params="{ item }">
        <p class="mb-0 params">{{ item.params }}</p>
      </template> -->

      <template v-if="$vuetify.breakpoint.width < 1400" v-slot:item="{ item }">
        <tr>
          <td class="w-100">
            <div class="mobile-table-item">
              <div class="mobile-table-item__values">
                <div class="mobile-table-item__row">
                  <div class="mobile-table-item__value">
                    <div class="d-flex align-center justify-space-between">
                      <user-item :user="item.user" />
                      <v-tooltip
                        open-delay="150"
                        close-delay="150"
                        class="ml-auto"
                        offset-y
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="mb-0 text-right gray--text text--darken-1"
                          >
                            {{ moment(item.date).fromNow() }}
                          </span>
                        </template>
                        <span>{{
                          moment(item.date).format("YYYY-MM-DD HH:mm")
                        }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div class="mobile-table-item__value">
                    <log-item :action="item" />
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:footer>
        <div>
          <data-iterator-footer
            :page="page"
            :numberOfPages="numberOfPages"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="itemsPerPage"
            :perPageLabel="$t('table.footer.log')"
            v-on="$listeners"
          >
          </data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";

import DataIteratorMixin from "../../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../../mixins/CustomTableMixin";

import DataIteratorFooter from "../DataIteratorFooter.vue";
import TableCustomSort from "../TableCustomSort.vue";
import LogsTableSkeletonItem from "./LogsTableSkeletonItem.vue";
import LogsTableSortSkeleton from "./LogsTableSortSkeleton.vue";
import LogsTableItemSkeletonMobile from "./LogsTableItemSkeletonMobile.vue";
import LogItem from "@/components/logs/LogItem.vue";
import UserItem from "@/components/logs/UserItem.vue";

import DataIteratorNoResultsContainer from "../DataIteratorNoResultsContainer.vue";

export default {
  components: {
    UserItem,
    DataIteratorFooter,
    TableCustomSort,
    LogsTableSkeletonItem,
    LogsTableSortSkeleton,
    LogsTableItemSkeletonMobile,
    LogItem,
    DataIteratorNoResultsContainer,
  },
  data: function () {
    return {
      canBeSelected: false,
      moment,
    };
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
  },
  methods: {
    clearFilters() {
      this.searchTerm = "";
    },
    formatDate: function (value) {
      if (!value) return "---";

      if (typeof value === "string") {
        value = parseInt(value);
      }

      var options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      const formatedDate = new Date(value).toLocaleDateString(
        undefined,
        options
      );

      let date = formatedDate.slice(0, formatedDate.indexOf(","));
      let time = formatedDate.slice(
        formatedDate.indexOf(",") + 1,
        formatedDate.length
      );
      date = `<span class="font-weight-bold">${date}</span>`;
      return date + time;
    },
  },
};
</script>

<style lang="scss" scoped>
.params {
  max-width: 450px;
}
.w-100 {
  width: 100%;
}
</style>
