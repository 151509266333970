<template>
  <thead class="item sort">
    <th v-for="item in 4" :key="item">
      <v-skeleton-loader type="text" />
    </th>
  </thead>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
thead {
  th {
    border-bottom: 1px solid map-get($gray, lighten2);

    height: 52px;
    &:first-child {
      .v-skeleton-loader {
        margin-left: 8px;
      }
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      width: 120px;
    }

    padding: 6px 16px;
    .v-skeleton-loader {
      max-width: 62px;
    }
  }
}
</style>